import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ReferenceArea,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import { DataQuery, SensorDataRequest } from '@services/data'
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import {
    EuiButtonIcon,
    EuiFlexGroup,
    EuiFlexItem,
    euiPaletteColorBlind,
} from '@elastic/eui'
import { useAppDispatch, useAppSelector } from '@hooks/store'

import moment from 'moment'
import { setDisabledLines } from '@store/network/network.slice'

// Do NOT use months as durations, because it will subtract 30 or 31 days for the query depending on the date
// But it will always compare the value as 30 days to determine what the previous/next step should be for zooming in or out
const ZOOM_STEPS = Object.freeze({
    '10min': moment.duration(10, 'minute'),
    '30min': moment.duration(30, 'minute'),
    '1hour': moment.duration(1, 'hour'),
    '2hours': moment.duration(2, 'hour'),
    '4hours': moment.duration(4, 'hour'),
    '12hours': moment.duration(12, 'hour'),
    '1day': moment.duration(1, 'day'),
    '2days': moment.duration(2, 'day'),
    '4days': moment.duration(4, 'day'),
    '1week': moment.duration(1, 'week'),
    '2weeks': moment.duration(2, 'week'),
    '1month': moment.duration(30, 'day'),
    '2months': moment.duration(59, 'day'),
    '6months': moment.duration(183, 'day'),
    '1year': moment.duration(366, 'day'),
})

const SCROLL_STEP_FACTOR = 0.1

type ZoomStepType = keyof typeof ZOOM_STEPS

export type ChartLine = {
    key: string
    label: string
    unit?: string
    hide?: boolean
}

export type ChartProps = {
    data: any
    query: DataQuery
    setQuery: Dispatch<SetStateAction<SensorDataRequest>>
}

type NetworkLineChartProps = {
    data: any
    query: DataQuery
    setQuery: Dispatch<SetStateAction<SensorDataRequest>>
    lines: ChartLine[]
    xAxis: string
    height?: number | string
    syncId?: string
    legendWidth?: number
    yAxisWidth?: number
    xAxisFormatter?: (value: any) => string
    xAxisDomain?: [number, number]
    yAxisType?: 'number' | 'category'
    yAxisDomain?: [number, number] | string[] | number[]
    yAxisFormatter?: (value: any) => string
    yAxisTickCount?: number
    isAnimationActive?: boolean
}

const getRoundedDuration = (duration: moment.Duration) => {
    if (duration.months() > 1)
        return moment.duration(Math.round(duration.asMonths()), 'month')

    return moment.duration(Math.round(duration.asMinutes()), 'minute')
}

const getNearestZoomSteps = (
    start: moment.Moment,
    end: moment.Moment
): [ZoomStepType | undefined, ZoomStepType | undefined] => {
    const duration = getRoundedDuration(moment.duration(end.diff(start)))

    let prev: ZoomStepType | undefined = undefined
    let next: ZoomStepType | undefined = undefined

    Object.entries(ZOOM_STEPS).forEach(([key, value]) => {
        if (duration < value && next === undefined) next = key as ZoomStepType
        if (duration > value) prev = key as ZoomStepType
    })

    return [prev, next]
}

const NetworkLineChart = ({
    data,
    query,
    setQuery,
    lines,
    xAxis,
    height,
    syncId,
    legendWidth,
    yAxisWidth,
    xAxisFormatter,
    xAxisDomain,
    yAxisType,
    yAxisDomain,
    yAxisFormatter,
    yAxisTickCount,
    isAnimationActive,
}: NetworkLineChartProps) => {
    const dispatch = useAppDispatch()

    const { dateFilter, disabledLines } = useAppSelector(
        (state) => state.network
    )

    const [activeLine, setActiveLine] = useState<string | undefined>(undefined)
    const [refAreaLeft, setRefAreaLeft] = useState<string | undefined>(
        undefined
    )
    const [refAreaRight, setRefAreaRight] = useState<string | undefined>(
        undefined
    )
    const [xDomain, setXDomain] = useState<typeof xAxisDomain>(xAxisDomain)
    const [yDomain, setYDomain] = useState<typeof yAxisDomain>(yAxisDomain)
    const [chartData, setChartData] = useState<typeof data>(data)

    const [zoomOnYAxis, setZoomOnYAxis] = useState<boolean>(false)
    const [[zoomIn, zoomOut], setZooms] = useState<
        [ZoomStepType | undefined, ZoomStepType | undefined]
    >([undefined, undefined])

    const colors = useMemo(() => {
        // If there is only one line, return a random color
        if (lines.length < 2) {
            const colorsList = euiPaletteColorBlind()
            return [colorsList[Math.floor(Math.random() * colorsList.length)]]
        }

        const r = Math.ceil(lines.length / 10)
        return euiPaletteColorBlind({ rotations: r, sortBy: 'natural' })
    }, [lines])

    const getColor = (index: number) => {
        const color = colors[index]
        if (color) return color
    }

    const getEndDate = (date: Date | undefined): Date | undefined => {
        // If the query is close to "now", set the end timestamp of query to undefined, so that it will auto-update using the polling interval
        if (moment.duration(moment().diff(date)) < moment.duration(5, 'minute'))
            return undefined

        return date
    }

    const getYAxisDomain = (domain: typeof yAxisDomain): typeof yAxisDomain => {
        if (!zoomOnYAxis && domain) return domain
        // For string values just return the values
        if (typeof domain?.[0] === 'string') return domain

        const defaultTop = domain?.[1] || 100
        const defaultBottom = domain?.[0] || 0

        let bottom: number | undefined
        let top: number | undefined

        lines.forEach((line) => {
            const values = data
                .map((x: any) => parseInt(x[line.key]))
                .filter((x: number) => !isNaN(x))

            const min = Math.min(...values)
            const max = Math.max(...values)

            if (bottom === undefined || min < bottom) bottom = min
            if (top === undefined || max > top) top = max
        })

        if (!zoomOnYAxis && !domain) {
            return [0, Math.min(top || 100, defaultTop as number)]
        }

        return [
            Math.max(bottom || 0, defaultBottom),
            Math.min(top || 100, defaultTop as number),
        ]
    }

    const handleMouseEnter = (o: any) => {
        const { dataKey } = o
        setActiveLine(dataKey)
    }

    const handleMouseLeave = (o: any) => {
        setActiveLine(undefined)
    }

    const handleOnClick = (e: any) => {
        const lineKey = e.dataKey.split('_')[0]
        if (disabledLines?.indexOf(lineKey) !== -1)
            dispatch(
                setDisabledLines(
                    [...disabledLines].filter((x) => x !== lineKey)
                )
            )
        else dispatch(setDisabledLines([...disabledLines, lineKey]))
    }

    const handleZoomInSelection = () => {
        if (
            refAreaLeft === refAreaRight ||
            refAreaRight === '' ||
            !refAreaLeft ||
            !refAreaRight
        ) {
            setRefAreaLeft(undefined)
            setRefAreaRight(undefined)
            return
        }

        let [left, right] = [parseFloat(refAreaLeft), parseFloat(refAreaRight)]
        if (left > right) [left, right] = [right, left]

        setQuery((prev) => ({
            ...prev,
            query: {
                type: 'custom',
                start: new Date(left),
                end: getEndDate(new Date(right)),
            },
        }))

        setRefAreaLeft(undefined)
        setRefAreaRight(undefined)
    }

    const handleZoom = (zoom: 'in' | 'out') => {
        if (!xAxisDomain) return

        const duration =
            zoom === 'in' && zoomIn
                ? ZOOM_STEPS[zoomIn]
                : zoom === 'out' && zoomOut
                  ? ZOOM_STEPS[zoomOut]
                  : undefined

        if (duration) {
            const domainEnd = moment(xAxisDomain[1])
            const end = query.end !== undefined ? domainEnd.toDate() : undefined
            const start = domainEnd.clone().subtract(duration).toDate()

            setQuery((prev) => ({
                ...prev,
                query: { type: 'custom', start, end: getEndDate(end) },
            }))
        }
    }

    const handleReset = () => {
        setQuery((prev) => ({ ...prev, query: { type: dateFilter } }))
    }

    const handleScroll = (
        type: 'step' | 'window',
        direction: 'left' | 'right'
    ) => {
        if (!xAxisDomain) return

        const step =
            (xAxisDomain[1] - xAxisDomain[0]) *
            (type === 'step' ? SCROLL_STEP_FACTOR : 1)

        let start = new Date(xAxisDomain[0])
        let end = new Date(xAxisDomain[1])

        if (direction === 'left') {
            start = new Date(xAxisDomain[0] - step)
            end = new Date(xAxisDomain[1] - step)
        }

        if (direction === 'right') {
            start = new Date(xAxisDomain[0] + step)
            end = new Date(xAxisDomain[1] + step)
        }

        setQuery((prev) => ({ ...prev, query: { type: 'custom', start, end } }))
    }

    const lineIsHidden = useCallback(
        (line: ChartLine) => {
            return disabledLines.some((x) => line.key.includes(x))
        },
        [disabledLines]
    )

    useEffect(() => {
        if (xAxisDomain) {
            const domainStart = moment(xAxisDomain[0])
            const domainEnd = moment(xAxisDomain[1])

            const zooms = getNearestZoomSteps(domainStart, domainEnd)
            setZooms(zooms)
        }

        setXDomain(xAxisDomain)
        setYDomain(getYAxisDomain(yAxisDomain))
        setChartData([...data])
    }, [data, xAxisDomain, yAxisDomain, zoomOnYAxis])

    return (
        <>
            <ResponsiveContainer width="100%" height={height || 500}>
                <LineChart
                    data={chartData}
                    syncId={syncId}
                    onMouseDown={(e) => setRefAreaLeft(e.activeLabel)}
                    onMouseMove={(e) =>
                        refAreaLeft && setRefAreaRight(e.activeLabel)
                    }
                    onMouseUp={handleZoomInSelection}
                >
                    {lines &&
                        lines.map((line, i) => (
                            <Line
                                legendType="line"
                                type={'monotone'}
                                dataKey={line.key}
                                name={line.label}
                                unit={line.unit}
                                stroke={getColor(i)}
                                strokeWidth={2}
                                isAnimationActive={
                                    isAnimationActive === undefined ||
                                    isAnimationActive === true
                                }
                                strokeOpacity={
                                    activeLine === undefined ||
                                    activeLine === line.key
                                        ? 1
                                        : 0.1
                                }
                                key={i}
                                dot={{ strokeWidth: 1, opacity: 0.5 }}
                                activeDot={{ r: 5 }}
                                connectNulls={false}
                                hide={lineIsHidden(line)}
                            />
                        ))}
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey={xAxis}
                        domain={xDomain}
                        scale="time"
                        tickFormatter={xAxisFormatter}
                    />
                    <YAxis
                        type={yAxisType ? yAxisType : 'number'}
                        domain={yDomain}
                        tickFormatter={yAxisFormatter}
                        padding={{ top: 30, bottom: 5 }}
                        width={yAxisWidth}
                        tickCount={yAxisTickCount ?? 5}
                    />
                    {refAreaLeft && refAreaRight && (
                        <ReferenceArea
                            x1={refAreaLeft}
                            x2={refAreaRight}
                            strokeOpacity={0.3}
                        />
                    )}
                    <Tooltip
                        animationDuration={300}
                        labelFormatter={(label: any) =>
                            !xAxisFormatter ? label : xAxisFormatter(label)
                        }
                        formatter={(value: string) =>
                            !yAxisFormatter ? value : yAxisFormatter(value)
                        }
                    />
                    <Legend
                        verticalAlign="top"
                        align="right"
                        layout="vertical"
                        width={legendWidth}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={handleOnClick}
                        wrapperStyle={{ padding: 15, cursor: 'pointer' }}
                    />
                </LineChart>
            </ResponsiveContainer>
            <EuiFlexGroup gutterSize="xs" justifyContent="center">
                <EuiFlexItem grow={false}>
                    <EuiButtonIcon
                        iconType="doubleArrowLeft"
                        onClick={() => handleScroll('window', 'left')}
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButtonIcon
                        iconType="arrowLeft"
                        onClick={() => handleScroll('step', 'left')}
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButtonIcon
                        iconType="magnifyWithPlus"
                        onClick={() => handleZoom('in')}
                        disabled={!zoomIn}
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButtonIcon
                        iconType="refresh"
                        onClick={() => handleReset()}
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButtonIcon
                        iconType="magnifyWithMinus"
                        onClick={() => handleZoom('out')}
                        disabled={!zoomOut}
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButtonIcon
                        iconType="arrowRight"
                        onClick={() => handleScroll('step', 'right')}
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButtonIcon
                        iconType="doubleArrowRight"
                        onClick={() => handleScroll('window', 'right')}
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButtonIcon
                        display={zoomOnYAxis ? 'fill' : 'empty'}
                        isSelected={zoomOnYAxis}
                        iconType={zoomOnYAxis ? 'fold' : 'unfold'}
                        onClick={() => setZoomOnYAxis(!zoomOnYAxis)}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    )
}

export default NetworkLineChart
