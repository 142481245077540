import { NetworkCollection, TreeMode, TreeNode } from '@services/network'
import { RowRendererProps, TreeApi } from 'react-arborist'
import { Sensor, SensorGroup, SensorType } from '@services/sensors'
import { useAppDispatch, useAppSelector } from '@hooks/store'
import { useCallback, useEffect, useRef, useState } from 'react'

import { Agent } from '@services/agents'
import { Device } from '@services/devices'
import { Group } from '@services/groups'
import { setActiveTreeNodeKey } from '@store/network'

export type TreeCountersState = {
    errors: number
    warnings: number
}

type useNetworkTreeProps = {
    mode: TreeMode
    data: NetworkCollection<Agent> | NetworkCollection<Group> | undefined
}

const WMI_TYPES = [
    SensorType.WmiProcessor,
    SensorType.WmiMemory,
    SensorType.WmiDisk,
]
const SSH_TYPES = [
    SensorType.SshProcessor,
    SensorType.SshMemory,
    SensorType.SshDisk,
]
const SNMP_TYPES = [SensorType.SnmpProcessor, SensorType.SnmpTraffic]

const useNetworkTree = ({ data, mode }: useNetworkTreeProps) => {
    const ref = useRef<TreeApi<TreeNode>>(null)
    const dispatch = useAppDispatch()
    const { activeNode } = useAppSelector((state) => state.network)
    const [activeNodeKey, setActiveNodeKey] = useState<string | undefined>(
        undefined
    )
    const [treeItems, setTreeItems] = useState<TreeNode[]>([])
    const [counterState, setCounterState] = useState<TreeCountersState>({
        errors: 0,
        warnings: 0,
    })

    const onRenderRow = useCallback(
        (renderRowProps: RowRendererProps<TreeNode>) => {
            return (
                <div {...renderRowProps.attrs} className="tree-node-row">
                    {renderRowProps.children}
                </div>
            )
        },
        [activeNode]
    )

    // Used to apply selected node from URL
    const isInitialSelectionApplied = useRef<boolean>(false)

    const combineSensorNodes = (
        parentPath: string | undefined,
        sensorGroupType: SensorGroup['type'],
        isDeviceEnabled: boolean,
        sensors: Sensor[]
    ): TreeNode => {
        const path = parentPath
            ? `${parentPath}/${sensorGroupType}`
            : sensorGroupType
        return {
            type: 'sensorGroup',
            id: `${sensorGroupType}_${sensors[0].deviceId}`,
            entity: {
                sensors: sensors,
                type: sensorGroupType,
            } as SensorGroup,
            children: [],
            isEnabled: isDeviceEnabled,
            path: path,
        }
    }

    const mapGroupData = (group: Group, parentPath?: string): TreeNode => {
        const key = `group_${group.id}`
        const path = parentPath ? `${parentPath}/${key}` : key

        const subGroups = group.subGroups
            ? group.subGroups.map((subGroup) => mapGroupData(subGroup, path))
            : []

        const devices = group.devices?.map((d) => mapDeviceData(d, path)) || []

        return {
            id: key,
            name: group.name,
            type: 'group',
            entity: group,
            children: [...subGroups, ...devices],
            path: path,
        }
    }

    const mapAgentData = (agent: Agent): TreeNode => {
        const key = `agent_${agent.id}`

        return {
            id: key,
            type: 'agent',
            path: key,
            entity: agent,
            name: agent.name,
            isEnabled: agent.isEnabled,
            children: agent.devices?.map((d) => mapDeviceData(d, key)) || [],
        }
    }

    const mapDeviceData = (device: Device, parentPath?: string): TreeNode => {
        const key = `device_${device.id}`

        if (activeNode?.type === 'device' && activeNode.data.id === device.id) {
            setActiveNodeKey(key)
        }

        const path = parentPath ? `${parentPath}/${key}` : key
        return {
            id: key,
            name: device.name,
            type: 'device',
            isEnabled: device.isEnabled,
            entity: device,
            children: mapSensorGroupsData(
                device.sensors || [],
                device.isEnabled,
                path
            ),
        }
    }

    const mapSensorGroupsData = (
        sensors: Sensor[],
        isDeviceEnabled: boolean,
        parentPath?: string
    ): TreeNode[] => {
        const results: TreeNode[] = []
        const wmiSensors = sensors.filter((sensor) =>
            WMI_TYPES.includes(sensor.type)
        )

        if (wmiSensors.length > 0)
            results.push(
                combineSensorNodes(
                    parentPath,
                    'WMI',
                    isDeviceEnabled,
                    wmiSensors
                )
            )

        const sshSensors = sensors.filter((sensor) =>
            SSH_TYPES.includes(sensor.type)
        )

        if (sshSensors.length > 0)
            results.push(
                combineSensorNodes(
                    parentPath,
                    'SSH',
                    isDeviceEnabled,
                    sshSensors
                )
            )

        const snmpSensors = sensors.filter((sensor) =>
            SNMP_TYPES.includes(sensor.type)
        )

        if (snmpSensors.length > 0)
            results.push(
                combineSensorNodes(
                    parentPath,
                    'SNMP',
                    isDeviceEnabled,
                    snmpSensors
                )
            )

        const otherSensors = sensors
            .filter(
                (sensor) =>
                    !WMI_TYPES.includes(sensor.type) &&
                    !SSH_TYPES.includes(sensor.type) &&
                    !SNMP_TYPES.includes(sensor.type)
            )
            .map((sensor) => mapSensorData(sensor, isDeviceEnabled, parentPath))

        return [...results, ...otherSensors] as TreeNode[]
    }

    const mapSensorData = (
        sensor: Sensor,
        isDeviceEnabled: boolean,
        parentPath?: string
    ): TreeNode => {
        const key = `sensor_${sensor.id}`

        if (activeNode?.type === 'sensor' && activeNode.data.id === sensor.id) {
            setActiveNodeKey(key)
        }

        const path = parentPath ? `${parentPath}/${key}` : key
        return {
            id: key,
            name: sensor.name,
            entity: sensor,
            isEnabled: sensor.isEnabled && isDeviceEnabled,
            type: 'sensor',
            children: null,
            path: path,
        }
    }

    const expandAllNodes = () => ref.current?.openAll()
    const collapseAllNodes = () => ref.current?.closeAll()

    // Sets the active tree node key when activeNode was set from the URL params
    useEffect(() => {
        if (
            treeItems.length &&
            activeNode &&
            !activeNode.treeNodeKey &&
            !isInitialSelectionApplied.current
        ) {
            dispatch(setActiveTreeNodeKey(activeNodeKey))
            isInitialSelectionApplied.current = true
        }
    }, [treeItems, activeNode])

    useEffect(() => {
        if (data) {
            if (mode === 'groups')
                setTreeItems(data.items.map((g) => mapGroupData(g as Group)))
            if (mode === 'agents')
                setTreeItems(data.items.map((a) => mapAgentData(a as Agent)))

            setCounterState({ errors: data.errors, warnings: data.warnings })
        }
    }, [data, mode])

    return {
        treeRef: ref,
        treeItems,
        counterState,
        expandAllNodes,
        collapseAllNodes,
        onRenderRow,
    }
}

export default useNetworkTree
