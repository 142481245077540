import { Sensor, SensorGroup, SensorType } from '@services/sensors'

import { Agent } from '@services/agents'
import { Device } from '@services/devices'
import { EuiIcon } from '@elastic/eui'
import { Group } from '@services/groups'
import { IdObj } from 'react-arborist/dist/module/types/utils'
import { NodeApi } from 'react-arborist'
import moment from 'moment'

export type TreeMode = 'groups' | 'agents'

export type TreeNode = IdObj & {
    name?: string
    isEnabled?: boolean
    children: TreeNode[] | null
    entity?: Agent | Group | Device | Sensor | SensorGroup
    type: 'group' | 'agent' | 'device' | 'sensor' | 'sensorGroup'
    path?: string
    isSelected?: boolean
}

export type TreeParams = {
    expandedKeys: string[]
    autoExpandParent: boolean
}

export const CUSTOM_CHART_FETCH_TYPES: SensorType[] = [SensorType.SnmpTraffic]

export const ALL_ACTIVATABLE_TREE_NODES = ['sensor', 'device']
export type ActivatableTreeNode = (typeof ALL_ACTIVATABLE_TREE_NODES)[number]

export const ALL_DETAILS_TREE_NODES = ['sensor', 'device', 'group', 'agent']
export type DetailsTreeNode = (typeof ALL_DETAILS_TREE_NODES)[number]
export type TreeState = {
    search?: string | undefined
    errors: boolean
    warnings: boolean
    expandAll: boolean
    sort: boolean
    categories?: number[]
    sessionId?: number | undefined
}

export interface NetworkCollection<T> {
    items: T[]
    errors: number
    warnings: number
}

export const getCustomDateFilter = (
    filter: moment.Moment | string | undefined
) => {
    if (moment.isMoment(filter)) return filter.toDate()
    if (!filter) return undefined
    return new Date(filter)
}

// Cache for tree nodes
const cachedIcons: { [key: string]: JSX.Element } = {}

export const getNodeIcon = (type: string) => {
    if (!cachedIcons[type]) {
        cachedIcons[type] = generateNodeIcon(type)
    }
    return cachedIcons[type]
}

export const isActiveTreeNode = (
    node: NodeApi<TreeNode>,
    activeNodeKey: string
): boolean => {
    if (node.id === activeNodeKey) return true
    if (node.children?.length)
        return node.children.some((c) => isActiveTreeNode(c, activeNodeKey))
    return false
}

export const encodeUrlTreeState = (state: TreeState) => {
    const parts: string[] = []

    Object.keys(state).forEach((key) => {
        const value = state[key as keyof TreeState] as any

        // Check if the value is an array
        if (Array.isArray(value)) {
            value.forEach((v) => {
                parts.push(`${key}=${v}`)
            })
            return
        }

        parts.push(`${key}=${value.toString()}`)
    })

    return parts.join('&')
}

const generateNodeIcon = (type: string) => {
    const iconStyle = { paddingRight: 4, display: 'flex' }

    switch (type) {
        case 'group':
            return <EuiIcon style={iconStyle} type="folderClosed" />
        case 'agent':
            return <EuiIcon style={iconStyle} type="reporter" />
        case 'device':
            return <EuiIcon style={iconStyle} type="desktop" />
        case 'wmi_sensors':
        case 'ssh_sensors':
        case 'snmp_sensors':
        case 'sensor':
            return <EuiIcon style={iconStyle} type="visGauge" />
        default:
            return <span></span>
    }
}
