import {
    ActivatableTreeNode,
    DetailsTreeNode,
    TreeMode,
} from '@services/network'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Agent } from '@services/agents'
import { Category } from '@services/categories'
import { DateFilterType } from '@services/data'
import { Device } from '@services/devices'
import { Group } from '@services/groups'
import { Sensor } from '@services/sensors'
import { TreeParams } from '@services/network/network.service'
import moment from 'moment'

export interface NetworkState {
    treeMode: TreeMode
    treeParams: TreeParams
    activeNode?: {
        data: Sensor | Device
        type: ActivatableTreeNode
        path?: string
        treeNodeKey?: string
    }
    detailsNode?: {
        data: Sensor | Device | Group | Agent
        type: DetailsTreeNode
    }
    dateFilter: DateFilterType
    customDateFilter?: [moment.Moment, moment.Moment]
    refreshTree?: any
    categoryFilter?: {
        isActive: boolean
        categories: Category[]
    }
    // Dictates which lines are enabled on the Line charts
    disabledLines: string[]
}

const initialState: NetworkState = {
    activeNode: undefined,
    treeMode: 'groups',
    dateFilter: 'today',
    customDateFilter: undefined,
    treeParams: {
        expandedKeys: [],
        autoExpandParent: false,
    },
    disabledLines: [],
}

export const networkSlice = createSlice({
    name: 'network',
    initialState,
    reducers: {
        setTreeMode: (state, { payload: treeMode }) => {
            state.treeMode = treeMode
            state.treeParams = { ...state.treeParams, expandedKeys: [] }
        },
        setTreeCategories: (
            state,
            action: PayloadAction<NetworkState['categoryFilter']>
        ) => {
            state.categoryFilter = action.payload
        },
        setActiveNode: (state, { payload: node }) => {
            state.activeNode = {
                data: node.data,
                type: node.type,
                path: node.path,
                treeNodeKey: node.treeNodeKey,
            }
        },
        setActiveTreeNodeKey: (state, { payload }) => {
            state.activeNode = state.activeNode
                ? { ...state.activeNode, treeNodeKey: payload }
                : undefined
        },
        setDateFilter: (state, { payload: filter }) => {
            state.dateFilter = filter
        },
        setCustomDateFilter: (state, { payload: range }) => {
            state.dateFilter = 'custom'
            state.customDateFilter = range
        },
        refreshTree: (state, { payload: refresh }) => {
            state.refreshTree = refresh
        },
        setTreeParams: (state, { payload: params }) => {
            state.treeParams = params
        },
        setDisabledLines: (state, { payload: lines }) => {
            state.disabledLines = lines
        },
    },
})

export const {
    setActiveNode,
    setDateFilter,
    setCustomDateFilter,
    setTreeMode,
    refreshTree,
    setTreeCategories,
    setTreeParams,
    setDisabledLines,
    setActiveTreeNodeKey,
} = networkSlice.actions
export default networkSlice.reducer
