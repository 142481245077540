import { ActionType, Item, ItemType, useContextMenuItems } from '@hooks/network'
import { EuiConfirmModal, EuiContextMenuPanel, EuiPopover } from '@elastic/eui'
import { useAppDispatch, useAppSelector } from '@hooks/store'
import {
    useDeleteAgentMutation,
    useDeleteDeviceMutation,
    useDeleteGroupMutation,
    useDeleteSensorMutation,
    usePauseSensorMutation,
} from '@services/api'
import { useEffect, useMemo, useState } from 'react'

import { refreshTree } from '@store/network'
import { setActiveContextMenu } from '@store/main'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type NetworkNodeContextMenuProps = {
    children: JSX.Element | JSX.Element[]
    type: ItemType
    item: Item
    isExpanded?: boolean
    toggleExpandAll?: () => void
    menuId?: string
}

const NetworkNodeContextMenu = (props: NetworkNodeContextMenuProps) => {
    const { t } = useTranslation(['common', 'network'])
    const { activeContextMenu } = useAppSelector((state) => state.main)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { getContextMenuItems } = useContextMenuItems()

    // States
    const [isOpen, setIsOpen] = useState(false)
    const [itemToDelete, setItemToDelete] = useState<
        { item: Item; type: ItemType } | undefined
    >(undefined)

    // Mutations
    const [pauseSensor] = usePauseSensorMutation()
    const [deleteSensor] = useDeleteSensorMutation()
    const [deleteDevice] = useDeleteDeviceMutation()
    const [deleteGroup] = useDeleteGroupMutation()
    const [deleteAgent] = useDeleteAgentMutation()

    const onClose = () => {
        dispatch(setActiveContextMenu(undefined))
    }

    const onContextMenu = (e: any) => {
        e.preventDefault()
        dispatch(setActiveContextMenu(props.menuId))
    }

    const onClickHandler = async (
        item: Item,
        type: ItemType,
        action: ActionType
    ) => {
        if (action === 'details' || action === 'edit') {
            navigate(`/network/${type}/${item.id}?referral=network`)
        } else if (action === 'install') {
            navigate(`/network/${type}/${item.id}/install?referral=network`)
        } else if (action === 'add-sub-group') {
            navigate(`/network/${type}/new/${item.id}?referral=network`)
        } else if (action === 'resume' || action === 'pause') {
            await pauseSensor({
                isPaused: action === 'pause',
                sensorId: item.id,
            })
            dispatch(refreshTree({}))
        } else if (action === 'delete') {
            setItemToDelete({ item, type })
        } else if (action === 'add-device') {
            navigate(
                `/network/device/new?${type}Id=${item.id}&referral=network`
            )
        } else if (action === 'add-sensor') {
            navigate(
                `/network/sensor/new?${type}Id=${item.id}&referral=network`
            )
        } else if (action === 'add-sensors-from-device-template') {
            navigate(
                `/network/device/addSensors?deviceId=${item.id}&referral=network`
            )
        } else if (action === 'toggleExpandAll') {
            props.toggleExpandAll?.()
        } else if (action === 'clone') {
            navigate(
                `/network/device/new?templateId=${item.id}&referral=network`
            )
        } else if (action === 'troubleshoot') {
            navigate(`/network/sensor/${item.id}/troubleshoot`)
        }

        onClose()
    }

    const contextMenu = getContextMenuItems(
        props.item,
        props.type,
        onClickHandler,
        props.isExpanded
    )

    const button = useMemo(
        () => <div onContextMenu={onContextMenu}>{props.children}</div>,
        [props.children]
    )

    const onDeleteConfirm = async () => {
        if (itemToDelete) {
            switch (itemToDelete.type) {
                case 'sensor':
                    await deleteSensor(itemToDelete.item.id)
                    break
                case 'device':
                    await deleteDevice(itemToDelete.item.id)
                    break
                case 'group':
                    await deleteGroup(itemToDelete.item.id)
                    break
                case 'agent':
                    await deleteAgent(itemToDelete.item.id)
                    break
            }

            dispatch(refreshTree({}))
            setItemToDelete(undefined)
        }
    }

    useEffect(() => {
        setIsOpen(activeContextMenu === props.menuId)
    }, [activeContextMenu, props.menuId])

    return (
        <>
            {itemToDelete && (
                <EuiConfirmModal
                    title={t('common:delete_item_confirm_title')}
                    onCancel={() => setItemToDelete(undefined)}
                    onConfirm={async () => await onDeleteConfirm()}
                    cancelButtonText={t('common:delete_confirm_cancel')}
                    confirmButtonText={t('common:delete_confirm_ok')}
                    buttonColor="danger"
                    defaultFocusedButton="confirm"
                >
                    <p>{t('common:delete_item_confirm_description')}</p>
                </EuiConfirmModal>
            )}
            <EuiPopover
                id={props.menuId}
                isOpen={isOpen}
                closePopover={onClose}
                button={button}
                panelPaddingSize="none"
                anchorPosition="downLeft"
            >
                <EuiContextMenuPanel
                    items={contextMenu.items}
                    title={contextMenu.title}
                />
            </EuiPopover>
        </>
    )
}

export default NetworkNodeContextMenu
