import {
    ALL_ACTIVATABLE_TREE_NODES,
    TreeNode,
    isActiveTreeNode,
} from '@services/network'
import {
    EuiBadge,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiText,
    EuiTextColor,
    EuiToolTip,
    transparentize,
} from '@elastic/eui'
import { NodeApi, NodeRendererProps } from 'react-arborist'
import { Sensor, SensorGroup, getSensorTagName } from '@services/sensors'
import { useAppDispatch, useAppSelector } from '@hooks/store'
import { useCallback, useMemo } from 'react'

import { Agent } from '@services/agents'
import { Device } from '@services/devices'
import { Group } from '@services/groups'
import NetworkNodeContextMenu from '../NetworkNodeContextMenu/NetworkNodeContextMenu'
import { formatFullDate } from '@utils/dates'
import { setActiveNode } from '@store/network'
import { useTranslation } from 'react-i18next'

const GROUP_ICON = <EuiIcon size="s" type="folderClosed" />
const AGENT_ICON = <EuiIcon size="s" type="reporter" />
const DEVICE_ICON = <EuiIcon size="s" type="desktop" />
const SENSOR_ICON = <EuiIcon size="s" type="visGauge" />

const colorsMap = {
    Offline: 'default',
    Online: 'success',
    Error: 'danger',
    Warning: 'warning',
    Paused: 'primary',
    Acknowledged: transparentize('#ff7e62', 0.5),
}

const NetworkNode = ({ node, style }: NodeRendererProps<TreeNode>) => {
    const { t } = useTranslation(['sensors'])
    const dispatch = useAppDispatch()
    const { activeNode } = useAppSelector((state) => state.network)

    const handleOnClick = (
        nodeData: Sensor | Device,
        type:
            | 'sensor'
            | 'device'
            | 'group'
            | 'agent'
            | 'wmi_sensors'
            | 'ssh_sensors'
            | 'snmp_sensors'
    ) => {
        if (ALL_ACTIVATABLE_TREE_NODES.includes(type)) {
            dispatch(
                setActiveNode({
                    data: { ...nodeData },
                    type: type,
                    path: node.data.path,
                    treeNodeKey: node.id,
                })
            )
        }
    }

    const toggleExpandAll = useCallback(() => {
        const expandAllSubNodes = (nodeToExpand: NodeApi<TreeNode>) => {
            nodeToExpand?.open()
            nodeToExpand.children?.forEach((c) => {
                expandAllSubNodes(c)
            })
        }

        const collapseAllSubNodes = (nodeToCollapse: NodeApi<TreeNode>) => {
            nodeToCollapse.children?.forEach((c) => {
                collapseAllSubNodes(c)
            })
            nodeToCollapse?.close()
        }

        if (node.isOpen) collapseAllSubNodes(node)
        else expandAllSubNodes(node)
    }, [node.state])

    const getSensorNodeColor = (sensor: Sensor) => {
        if (sensor.isPaused) {
            return colorsMap['Paused']
        }

        return colorsMap[sensor.monitoringStatus ?? 'Online']
    }

    const getSensorNode = (sensor: Sensor, isEnabled: boolean) => (
        <div className="tree-node-leaf">
            <NetworkNodeContextMenu
                menuId={`sensor-${sensor.id}`}
                type={'sensor'}
                item={sensor}
            >
                <EuiToolTip
                    content={
                        sensor.isPaused && sensor.pausedUntil
                            ? t('sensors:paused_until', {
                                  date: formatFullDate(sensor.pausedUntil),
                              })
                            : undefined
                    }
                    anchorProps={{
                        style: { overflow: 'hidden', textOverflow: 'ellipsis' },
                    }}
                >
                    <EuiBadge
                        color={getSensorNodeColor(sensor)}
                        onClickAriaLabel="Open sensor"
                        iconOnClickAriaLabel="Open sensor"
                        isDisabled={!isEnabled}
                        style={{
                            marginRight: 1,
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        iconType={sensor.isPaused ? 'pause' : undefined}
                        onClick={() => handleOnClick(sensor, 'sensor')}
                        iconOnClick={() => handleOnClick(sensor, 'sensor')}
                    >
                        {getSensorTagName(sensor)}
                    </EuiBadge>
                </EuiToolTip>
            </NetworkNodeContextMenu>
        </div>
    )

    const getSensorGroupNode = (group: SensorGroup, isEnabled: boolean) => (
        <EuiFlexGroup
            gutterSize="xs"
            alignItems="center"
            justifyContent="flexStart"
            wrap={true}
        >
            {group.sensors.map((s) => (
                <EuiFlexItem
                    key={s.id}
                    grow={false}
                    className={
                        activeNode?.treeNodeKey === node.id &&
                        activeNode?.data.id === s.id
                            ? 'active-node'
                            : ''
                    }
                >
                    {getSensorNode(s, isEnabled)}
                </EuiFlexItem>
            ))}
        </EuiFlexGroup>
    )

    const icon = useMemo(() => {
        switch (node.data.type) {
            case 'agent':
                return AGENT_ICON
            case 'device':
                return DEVICE_ICON
            case 'group':
                return GROUP_ICON
            case 'sensor':
            case 'sensorGroup':
                return SENSOR_ICON
            default:
                return <></>
        }
    }, [node])

    const className = useMemo(() => {
        if (!activeNode?.treeNodeKey || node.data.type === 'sensorGroup')
            return ''

        return isActiveTreeNode(node, activeNode.treeNodeKey)
            ? 'active-node'
            : ''
    }, [node, activeNode?.treeNodeKey])

    const nodeColor = useMemo(() => {
        if (!node.data.isEnabled && node.data.type !== 'group')
            return 'lightGrey'

        if (node.data.type === 'sensor')
            switch ((node.data.entity as Sensor)?.monitoringStatus) {
                case 'Offline':
                case 'Error':
                    return 'danger'
                case 'Warning':
                    return 'warning'
                default:
                    return 'default'
            }

        return 'default'
    }, [node.data.entity, node.data.isEnabled, node.data.type])

    const fontWeight = useMemo(() => {
        return nodeColor === 'default' ||
            !node.data.isEnabled ||
            node.data.isSelected
            ? 'bold'
            : 'normal'
    }, [nodeColor, node.data.isEnabled, node.data.isSelected])

    const onDeviceClick = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        if (node.data.type === 'device')
            handleOnClick(node.data.entity as Device, 'device')
    }

    const defaultNode = useMemo(
        () => (
            <EuiTextColor
                color={nodeColor}
                style={{ fontWeight }}
                onClick={onDeviceClick}
            >
                <EuiText
                    size="s"
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {node.data.name}
                </EuiText>
            </EuiTextColor>
        ),
        [nodeColor, fontWeight, node.data.name]
    )

    const renderedNode = useMemo(() => {
        let data: Agent | Device | Group | undefined
        switch (node.data.type) {
            case 'sensor':
                return getSensorNode(
                    node.data.entity as Sensor,
                    !!node.data.isEnabled
                )
            case 'sensorGroup':
                return getSensorGroupNode(
                    node.data.entity as SensorGroup,
                    !!node.data.isEnabled
                )
            case 'agent':
            case 'device':
            case 'group':
            default:
                switch (node.data.type) {
                    case 'agent':
                        data = node.data.entity as Agent
                        break
                    case 'device':
                        data = node.data.entity as Device
                        break
                    case 'group':
                        data = node.data.entity as Group
                        break
                    default:
                        break
                }

                return (
                    <div className="tree-node">
                        {data ? (
                            <NetworkNodeContextMenu
                                menuId={`${node.data.type}-${data?.id}`}
                                isExpanded={node.isOpen}
                                toggleExpandAll={toggleExpandAll}
                                type={node.data.type}
                                item={data}
                            >
                                {defaultNode}
                            </NetworkNodeContextMenu>
                        ) : (
                            defaultNode
                        )}
                    </div>
                )
        }
    }, [node.id, node.data, node.isOpen, node.isClosed, activeNode])

    return (
        <EuiFlexGroup
            style={{
                ...style,
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
                cursor: 'pointer',
            }}
            onClick={() => node.toggle()}
            direction="row"
            gutterSize="s"
            alignItems="center"
            className={className}
        >
            <EuiFlexItem grow={false}>{icon}</EuiFlexItem>
            <EuiFlexItem grow={false} style={{ maxWidth: '90%' }}>
                {renderedNode}
            </EuiFlexItem>
            {node.data.children && node.data.children?.length > 0 && (
                <EuiFlexItem grow={false}>
                    <EuiText
                        style={{ paddingTop: 2 }}
                        size="xs"
                        color="subdued"
                    >
                        ({node.data.children.length})
                    </EuiText>
                </EuiFlexItem>
            )}
        </EuiFlexGroup>
    )
}

export default NetworkNode
