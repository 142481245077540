import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { createMigrate, persistReducer } from 'redux-persist'

import alarmsSlice from './alarms/alarms.slice'
import { appApi } from '@services/api'
import authSlice from './auth/auth.slice'
import errorHandlingMiddleware from './errors.middleware'
import { isDebug } from '@utils/globals'
import mainSlice from './main/main.slice'
import networkSlice from './network/network.slice'
import notificationsSlice from './notifications/notifications.slice'
import storage from 'redux-persist/lib/storage'
import { storeMigrations } from './store.migrations'
import toastsSlice from './toasts/toasts.slice'

const reducers = combineReducers({
    [appApi.reducerPath]: appApi.reducer,
    main: mainSlice,
    auth: authSlice,
    toasts: toastsSlice,
    network: networkSlice,
    alarms: alarmsSlice,
    notifications: notificationsSlice,
})

const persistedReducers = persistReducer(
    {
        version: 7,
        key: 'smart-telemetry',
        storage: storage,
        whitelist: ['auth', 'network', 'alarms'],
        migrate: createMigrate(storeMigrations, { debug: isDebug() }),
    },
    reducers
)

export const store = configureStore({
    reducer: persistedReducers,
    devTools: isDebug(),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(appApi.middleware, errorHandlingMiddleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
