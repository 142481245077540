import {
    DataPoint,
    DataQuery,
    getPollingInterval,
    mapData,
} from '@services/data'
import { useCallback, useState } from 'react'
import {
    useFetchNetworkSensorDataQuery,
    useFetchNetworkSensorSnapshotQuery,
} from '@services/api'

import { ChartLine } from '@components/network/NetworkChart/NetworkLineChart'
import { SnmpDataPointKeys } from '@services/sensors'
import _ from 'lodash'
import i18n from '@locale/i18n'

type TrafficChart = {
    key: number
    title: string
    lines: ChartLine[]
    maxBandwidth: number
}

type SnmpChartData = {
    bandwidth: TrafficChart[]
    errors: TrafficChart[]
    packets: TrafficChart[]
}

const buildCharts = (snapshot: DataPoint | undefined) => {
    const charts: SnmpChartData = {
        bandwidth: [],
        errors: [],
        packets: [],
    }

    if (!snapshot) return charts

    const numOfInterfaces = parseInt(
        snapshot.values[SnmpDataPointKeys.numOfInterfaces] ?? 0
    )

    for (let index = 0; index < numOfInterfaces; index++) {
        const name =
            snapshot.values[`${SnmpDataPointKeys.interfaceName}_${index}`]

        if (!name) continue

        charts.bandwidth.push({
            key: index,
            title: name,
            lines: [
                {
                    key: `${SnmpDataPointKeys.bandwith.outgoing}_${index}`,
                    label: i18n.t(
                        'network:data_key_snmp_traffic_interface_outgoing_bandwidth_mbps'
                    ),
                },
                {
                    key: `${SnmpDataPointKeys.bandwith.incoming}_${index}`,
                    label: i18n.t(
                        'network:data_key_snmp_traffic_interface_incoming_bandwidth_mbps'
                    ),
                },
                {
                    key: `${SnmpDataPointKeys.bandwith.total}_${index}`,
                    label: i18n.t(
                        'network:data_key_snmp_traffic_interface_total_bandwith_mbps'
                    ),
                },
                {
                    key: `${SnmpDataPointKeys.bandwith.max}_${index}`,
                    label: i18n.t(
                        'network:data_key_snmp_traffic_interface_maximum_bandwidth_mbps'
                    ),
                },
            ],
            maxBandwidth: 100,
        })

        charts.errors.push({
            key: index,
            title: name,
            lines: [
                {
                    key: `${SnmpDataPointKeys.errors.outgoing}_${index}`,
                    label: i18n.t(
                        'network:data_key_snmp_traffic_interface_outgoing_errors'
                    ),
                },
                {
                    key: `${SnmpDataPointKeys.errors.incoming}_${index}`,
                    label: i18n.t(
                        'network:data_key_snmp_traffic_interface_incoming_errors'
                    ),
                },
            ],
            maxBandwidth: 100,
        })

        charts.packets.push({
            key: index,
            title: name,
            lines: [
                {
                    key: `${SnmpDataPointKeys.unicastPkts.outgoing}_${index}`,
                    label: i18n.t(
                        'network:data_key_snmp_traffic_interface_outgoing_unicast_packets'
                    ),
                },
                {
                    key: `${SnmpDataPointKeys.unicastPkts.incoming}_${index}`,
                    label: i18n.t(
                        'network:data_key_snmp_traffic_interface_incoming_unicast_packets'
                    ),
                },
                {
                    key: `${SnmpDataPointKeys.nonUnicastPkts.outgoing}_${index}`,
                    label: i18n.t(
                        'network:data_key_snmp_traffic_interface_outgoing_non_unicast_packets'
                    ),
                },
                {
                    key: `${SnmpDataPointKeys.nonUnicastPkts.incoming}_${index}`,
                    label: i18n.t(
                        'network:data_key_snmp_traffic_interface_incoming_non_unicast_packets'
                    ),
                },
            ],
            maxBandwidth: 100,
        })
    }

    return charts
}

let interfaces: string[] = []
let prevInterfaces: string[] = []

let disabledLines: string[] = []

const useSnmpData = (sensor: number, query: DataQuery) => {
    const [currentInterfaces, setCurrentInterfaces] = useState<
        string[] | undefined
    >(undefined)

    const { charts, isLoading: isSnapshotLoading } =
        useFetchNetworkSensorSnapshotQuery(sensor, {
            selectFromResult: ({ data, isLoading }) => ({
                charts: buildCharts(data),
                isLoading,
            }),
        })

    const { data: chartsData, isLoading: isSensorDataLoading } =
        useFetchNetworkSensorDataQuery(
            { sensor, query: { ...query, interfaces, disabledLines } },
            {
                skip: !currentInterfaces,
                pollingInterval: getPollingInterval(query),
                selectFromResult: ({ data, isLoading }) => ({
                    data: mapData(data as DataPoint[]),
                    isLoading,
                }),
            }
        )

    const refetchDataDebounced = useCallback(
        _.debounce(() => {
            if (interfaces && interfaces.length > 0)
                setCurrentInterfaces([...interfaces])

            prevInterfaces = [...interfaces]
        }, 300),
        [interfaces]
    )

    const onInterfaceChange = useCallback(
        (interfaceNr?: string, isActive?: boolean) => {
            if (!interfaceNr) return

            const newInterfaces = [...(interfaces ?? [])]
            const index = newInterfaces.indexOf(interfaceNr)

            if (isActive && index === -1) {
                newInterfaces.push(interfaceNr)
            } else if (!isActive && index !== -1) {
                newInterfaces.splice(index, 1)
            }

            interfaces = newInterfaces

            if (_.eq(interfaces, prevInterfaces)) return

            refetchDataDebounced()
        },
        []
    )

    const onDisabledLinesChange = useCallback((lines?: string[]) => {
        if (!lines) return

        disabledLines = lines

        refetchDataDebounced()
    }, [])

    return {
        charts,
        chartsData,
        onInterfaceChange,
        onDisabledLinesChange,
        isLoading: isSnapshotLoading || isSensorDataLoading,
    }
}

export default useSnmpData
